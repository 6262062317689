import { Injectable } from '@angular/core';
import {
  Database,
  ListenEvent,
  onChildAdded,
  onChildChanged,
  onChildMoved,
  onChildRemoved,
  onValue,
  ref,
} from '@angular/fire/database';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private database: Database) {}
  list<T>({
    rootPath,
    subPath,
    listenForEvent = ListenEvent.value,
    onlyOnce = false,
  }: {
    rootPath: 'auction';
    subPath: string;
    listenForEvent?:
      | ListenEvent.added
      | ListenEvent.removed
      | ListenEvent.value
      | ListenEvent.moved
      | ListenEvent.changed;
    onlyOnce?: boolean;
  }) {
    return new Observable<T>((subscriber) => {
      if (!(rootPath && subPath)) {
        subscriber.next(undefined);
        subscriber.error('root path and sub path must be present');
        return;
      }

      const docRef = ref(this.database, `${rootPath}/${subPath}`);

      switch (listenForEvent) {
        case ListenEvent.added: {
          onChildAdded(
            docRef,
            (snapshot) => {
              subscriber.next(snapshot.val());
            },
            { onlyOnce }
          );
          break;
        }
        case ListenEvent.moved: {
          onChildMoved(
            docRef,
            (snapshot) => {
              subscriber.next(snapshot.val());
            },
            { onlyOnce }
          );
          break;
        }
        case ListenEvent.changed: {
          onChildChanged(
            docRef,
            (snapshot) => {
              subscriber.next(snapshot.val());
            },
            { onlyOnce }
          );
          break;
        }
        case ListenEvent.removed: {
          onChildRemoved(
            docRef,
            (snapshot) => {
              subscriber.next(snapshot.val());
            },
            { onlyOnce }
          );
          break;
        }
        case ListenEvent.value: {
          onValue(
            docRef,
            (snapshot) => {
              subscriber.next(snapshot.val());
            },
            { onlyOnce }
          );
        }
      }
    });
  }
}
